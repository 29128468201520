// Variable overrides
$primary-base: #cc0000 !default;
$primary-100: #eae9fb !default;
$primary-200: #d6d2fb !default;
$primary-300: #c1bcf4 !default;
$primary-400: #ada5f1 !default;
$primary-500: #988fed !default;
$primary-600: #8478ea !default;
$primary-700: #cc0000 !default;
$primary-800: #cc0000 !default;
$primary-900: #cc0000!default;
$primary-dark: #cc0000 !default;
$primary: #990000 !default;
$gray-base:#990000;
$body-color:#3c4b64